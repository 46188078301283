import React, {
  ReactNode, useEffect, useMemo, useState
} from 'react';
import ServiceName from '@/components/ServiceName';
import { Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { apiState, categoriesState, tagsState } from '@/recoil/atoms/search';
import useSearchServices from './useSearchServices';

const defaultSearchApiUrl = '/carousel/search';
const defaultPublicSearchApiUrl = '/carousel/publicSearch';

const useSearchTitle = (marketCountry: string, usePublicNetwork: boolean) => {
  const tags = useRecoilValue(tagsState);
  const searchApiUrl = useRecoilValue(apiState);
  const categories = useRecoilValue(categoriesState);

  const services = useSearchServices(marketCountry);

  const [title, showTitle] = useMemo(() => {
    if (tags.length === 1) {
      return [
        <ServiceName
          value={services?.find(({ tag }) => tags.includes(tag))?.name}
        />,
        false,
      ];
    }
    if (
      !searchApiUrl.startsWith(
        usePublicNetwork ? defaultPublicSearchApiUrl : defaultSearchApiUrl
      )
    ) {
      const queryNamespace = searchApiUrl
        .replace(/^\//, '')
        .replaceAll(/\//g, '-');
      if (categories.length) {
        const [category] = categories;
        return [<Trans i18nKey={`${queryNamespace}-${category}`} />, true];
      }
      return [<Trans i18nKey={queryNamespace} />, true];
    }
    return [<Trans i18nKey='search-default-title'>Search</Trans>, false];
  }, [tags, searchApiUrl, categories, services, usePublicNetwork]);

  return [title, showTitle];
};

export default useSearchTitle;
