import { SortDirection } from '@/api/search';
import { DEFAULT_SEARCH_API_URL } from '@/constants/search';
import {
  apiState,
  categoriesState,
  directionState,
  filtersState,
  marketCountryState,
  pathState,
  propsState,
  queryState,
  tagsState,
} from '@/recoil/atoms/search';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

const SearchPathObserver = () => {
  const location = useLocation();
  const setPathName = useSetRecoilState(pathState);
  const setQuery = useSetRecoilState(queryState);
  const setTags = useSetRecoilState(tagsState);
  const setCategories = useSetRecoilState(categoriesState);
  const setProps = useSetRecoilState(propsState);
  const setDirection = useSetRecoilState(directionState);
  const setFilters = useSetRecoilState(filtersState);
  const setApi = useSetRecoilState(apiState);
  const setMarketCountry = useSetRecoilState(marketCountryState);

  const resetQuery = useResetRecoilState(queryState);
  const resetTags = useResetRecoilState(tagsState);
  const resetCategories = useResetRecoilState(categoriesState);
  const resetProps = useResetRecoilState(propsState);
  const resetDirection = useResetRecoilState(directionState);
  const resetFilters = useResetRecoilState(filtersState);
  const resetApi = useResetRecoilState(apiState);
  const resetMarketCountry = useResetRecoilState(marketCountryState);
  const resetPathName = useResetRecoilState(pathState);

  useEffect(() => {
    const { search, pathname } = location;
    const searchParams = new URLSearchParams(search);
    const newPath = [pathname, search].join('');

    // If user click see all from home carousels then do not save last path
    const api = searchParams.get('api');
    const tags = searchParams.get('tags');
    const categories = searchParams.get('categories');

    /**
     * to save last path
     * api = null || api = DEFAULT_SEARCH_API_URL
     * tags.length = 0
     * categories.lenth = 0
     *  */

    if (
      (!api || (api && api === DEFAULT_SEARCH_API_URL)) &&
      !tags?.length &&
      !categories?.length
    ) {
      setPathName(newPath);
    } else resetPathName();
  }, [location, setPathName]);

  /**
   * ? INFO(suley): We are going to change states only if user change the page
   * Because we already change query params with recoil states.
   *  */
  useEffect(() => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const query = searchParams.get('q');
    const tags = searchParams.get('tags');
    const categories = searchParams.get('categories');
    const props = searchParams.get('props');
    const direction = searchParams.get('direction');
    const filters = searchParams.get('filters');
    const api = searchParams.get('api');
    const marketCountry = searchParams.get('marketCountry');

    if (query) {
      setQuery(query);
    } else {
      resetQuery();
    }
    if (tags) {
      setTags(tags.split(','));
    } else {
      resetTags();
    }
    if (categories) {
      setCategories(categories.split(','));
    } else {
      resetCategories();
    }
    if (props) {
      setProps(props.split(','));
    } else {
      resetProps();
    }
    if (direction) {
      setDirection(direction as SortDirection);
    } else {
      resetDirection();
    }
    if (filters) {
      setFilters(filters.split(','));
    } else {
      resetFilters();
    }
    if (api) {
      setApi(api);
    } else {
      resetApi();
    }
    if (marketCountry) {
      setMarketCountry(marketCountry);
    } else {
      resetMarketCountry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <></>;
};

export default SearchPathObserver;
